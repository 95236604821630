import React from "react"
import { graphql } from "gatsby"

import { Maintenance as Page } from "../components/Maintenance/Maintenance"

export const query = graphql`
  query {
    page: sanitySettingMaintenance {
      title
      password
      enabled
      content
      image: _rawBackgroundImage(resolveReferences: { maxDepth: 10 })
    }
  }
`

export default ({ data, ...props }): JSX.Element => <Page {...props} {...data} />
