import React from "react"

import { withMaintenanceForm } from "./withMaintenanceForm"
import { Form, Field, Button, ButtonWrapper } from "../Form/Form.styled"

export const MaintenanceForm = withMaintenanceForm(
  ({ data, error, handleChange, handleSubmit }): JSX.Element => (
    <Form onSubmit={handleSubmit}>
      <Field name={"password"} type={"password"} placeholder={"Enter password"} value={data?.password} onChange={handleChange} required autoFocus />
      {error && <p>Invalid password</p>}

      <ButtonWrapper center={true}>
        <Button type="submit" center={true} size={`large`} colour={`dark`} theme={`primary`}>
          Enter
        </Button>
      </ButtonWrapper>
    </Form>
  )
)
