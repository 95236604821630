import React, { useMemo } from "react"

import { useMaintenance } from "../../hooks/useMaintenance"

export const withMaintenanceForm = Component =>
  React.memo(({ name = "MaintenanceForm", location }) => {
    const { data, error, handleChange, handleSubmit } = useMaintenance(location)
    Component.displayName = name
    return useMemo(() => <Component data={data} error={error} handleChange={handleChange} handleSubmit={handleSubmit} />, [data, error])
  })
