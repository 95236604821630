import React, { useMemo } from "react"

export const withMaintenance = Component =>
  React.memo(({ name = "Maintenance", page, location }) => {
    const title = page?.title
    const content = page?.content
    const image = page?.image?.asset?.url

    Component.displayName = name
    return useMemo(() => <Component title={title} content={content} image={image} location={location} />, [])
  })
