import React from "react"

import { withMaintenance } from "./withMaintenance"
import { MaintenanceForm } from "./MaintenanceForm"
import { Container, Frame, Title, Description } from "../Form/Form.styled"

export const Maintenance = withMaintenance(
  ({ title, content, location }): JSX.Element => (
    <Container width={`md`}>
      <Frame>
        <Title description={content}>{title}</Title>
        {content && <Description>{content}</Description>}
        <MaintenanceForm location={location} />
      </Frame>
    </Container>
  )
)
